<template>
<v-app>
    <v-content>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md6>
                    <v-card class="elevation-12">
                        <v-toolbar dark color="blue-grey darken-4">
                            <router-link style="cursor:pointer;" to="/">
                                <v-img height="30" position="left center" contain src="/img/logo_sun_n30.png" />
                            </router-link>
                            <v-spacer></v-spacer>
                            <v-icon color="grey" class="ml-n4">mdi-account-multiple-outline</v-icon>
                        </v-toolbar>
                        <v-card-title class="d-block text-center my-4">
                            Анкета нового клієнта
                        </v-card-title>
                        <v-card-subtitle class="mb-4" v-if="!userInfo().id">
                            Щоб розпочати співробітництво надайте, будь ласка, дані про вашу компанію.
                        </v-card-subtitle>
                        <v-card-subtitle class="mb-4 text-center" v-if="userInfo().id">
                            Дякуємо, але Ви вже співпрацюєте з нами.
                        </v-card-subtitle>
                        <v-card-text v-if="!userInfo().id">
                            <v-text-field outlined name="name" label="Назва компанії *" type="text" v-model="f.name"></v-text-field>
                            <v-subheader class="text-subtitle-2">Яким чином ви продаєте основний об'єм товару вашим клієнтам?<span class="primary--text ml-2">*</span></v-subheader>
                            <v-radio-group row v-model="f.salesType">
                                <v-radio :label="st.name" :value="st.id" v-for="(st, key) in salesTypes" :key="key"></v-radio>
                            </v-radio-group>
                            <v-subheader class="text-subtitle-2">Що формує основний об'єм продажів у вашій компанії?<span class="primary--text ml-2">*</span></v-subheader>
                            <v-radio-group v-model="f.salesChannel">
                                <v-radio :label="st.name" :value="st.id" v-for="(st, key) in saleChannels" :key="key"></v-radio>
                            </v-radio-group>
                            <v-subheader class="text-subtitle-2">У якому регіоні знаходитесь?<span class="primary--text ml-2">*</span></v-subheader>
                            <v-autocomplete v-model="f.region" outlined :items="regions" label="Оберіть регіон зі списку"></v-autocomplete>
                            <v-subheader class="text-subtitle-2">Надайте додаткову інформацію, яка конкретизує ваш запит (продукт, бренд тощо):</v-subheader>
                            <!-- <v-text-field outlined label="Діючий сайт" placeholder="https://" type="url" v-model="f.site"></v-text-field> -->
                            <v-textarea outlined label="Додаткова інформація" type="text" v-model="f.comment"></v-textarea>
                            <v-subheader class="text-subtitle-2">Контактна особа</v-subheader>
                            <v-text-field outlined label="ПІБ *" type="text" v-model="f.contactName"></v-text-field>
                            <v-text-field outlined label="Посада" type="text" v-model="f.contactPosition"></v-text-field>
                            <v-text-field outlined label="Тел. *" placeholder="+38(XXX) XXX - XXXX" v-mask="maskTel" type="text" v-model="f.contactPhone"></v-text-field>
                            <v-text-field outlined label="Email *" type="text" v-model="f.contactEmail"></v-text-field>
                        </v-card-text>
                        <v-card-actions class="mb-4">
                            <v-spacer></v-spacer>
                            <v-btn :disabled="!checkForm" @click="submitForm()" :loading="loading" color="primary">Надіслати</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                        <v-card-text class="caption text-center py-2 grey lighten-3"><a href="https://yugcontract.ua" target="_blank">yugcontract.ua</a></v-card-text>
                    </v-card>

                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</v-app>
</template>

<script>
import {
    mask
} from 'vue-the-mask'

import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    components: {},
    directives: {
        mask
    },
    data: () => ({
        maskTel: '+38(###) ###-####',
        f: {},
        errorMessage: ''
    }),
    props: {},
    methods: {
        ...mapActions(['getNewClientPageData', 'addNewClient', 'setSnackbar','getUserInfo']),
        ...mapGetters(['userInfo']),
        async submitForm() {
            // Execute reCAPTCHA with action "login".
            await this.$recaptchaLoaded()
            this.f.reCAPTCHA = await this.$recaptcha('newClient')
            this.addNewClient(this.f)
                .then(data => {
                    if (data.status == 'ok') {
                        this.f = {}
                        this.$vuetify.goTo(0)
                        this.setSnackbar(['success', 'Дякуємо. Ваш запит прийнято!'])
                    } else {
                        this.setSnackbar(['error', data.error])
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status == 417) {
                        this.errorMessage = 'Не пройдено перевірку безпеки. Оновіть сторінку та спробуйте ще раз.'
                    }
                    this.setSnackbar(['error', this.errorMessage])
                    console.log(error)
                })
        }
    },
    created() {
        this.getUserInfo()
        this.getNewClientPageData()
    },
    computed: {
        salesTypes() {
            return this.$store.state.main.newClientPageData && this.$store.state.main.newClientPageData.salesTypes
        },
        saleChannels() {
            return this.$store.state.main.newClientPageData && this.$store.state.main.newClientPageData.saleChannels
        },
        regions() {
            return this.$store.state.main.newClientPageData && this.$store.state.main.newClientPageData.regions
        },
        checkForm() {
            // return true
            return this.f.name && this.f.salesType && this.f.salesChannel &&
                this.f.contactName &&
                this.f.contactPhone && this.f.contactPhone.length == 17 &&
                this.f.contactEmail && this.f.contactEmail.match(/[-a-zA-Z0-9._]+\@[-a-zA-Z0-9._]+\.\w+/) &&
                // this.f.region && this.f.comment ? true : false
                this.f.region ? true : false
        },
        loading() {
            return this.$store.state.main.loading
        },

    }

}
</script>
